import React, { useState, useEffect } from "react";
import Input from "../components/common/Input";
import Empty from "../assets/svgs/empty.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import { Button, Table, Spin, Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "../styles/main.css";
// import empty from "../assets/empty.jpeg";
import View from "../assets/svgs/view.svg";
// import StatusBadge from "../components/common/StatusBadge";
import { authenticatedUser } from "../helper/AuthHelper";
import { VEHICLE_TYPES } from "../constants/Enums";
import { getKeyByValue } from "../helper/UtilityHelper";
// import { paginate } from "../utils/paginate";
import VehicleStatusBadge from "../components/common/VehicleStatusBadge";

const VehicleList = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  // const [statusChanged, setStatusChanged] = useState(false);
  const [displayedData, setDisplayedData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalVehicles, setTotalVehicles] = useState(0);

  const userDetails = authenticatedUser();
  const isEmployeeDriver =
    userDetails?.user_type === "driver" && userDetails?.is_employee;

  const getVehicleData = () => {
    props
      .getVehicles({ page: pageNumber - 1, limit: pageSize })
      .then((res) => {
        // if (data.length === 0) {
        // const newData = [];
        setTotalVehicles(res.payload.data.count);
        setData(res.payload.data.vehicles);

        // res.payload.data.vehicles.forEach((vehicle) => {
        //   if (!vehicle.tracking_id || vehicle.tracking_id === "") {
        //     vehicle.tracker = "N/A";
        //   }
        //   //  else {
        //   //   props
        //   //     .getTrackersHealth([vehicle.tracking_id])
        //   //     .then((res) => {
        //   //       vehicle.tracker = res?.payload?.data[0]?.status;
        //   //       setStatusChanged((statusChanged) => !statusChanged);
        //   //       // return res?.payload?.data[0]?.status
        //   //     })
        //   //     .catch((e) => {
        //   //       // vehicle.tracker = "pending"
        //   //     });
        //   // }
        //   newData.push(vehicle);
        // });

        // }
      })
      .catch((error) => {
        // toast.error('Error getting vehicles data!');
        return error;
      });
  };

  // useEffect(() => {
  //   setData(data2);
  // }, [statusChanged]);

  const handleSearchChange = ({ currentTarget: input }) => {
    setSearchInput(input.value);
  };

  const handlePageChange = (page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    const newData = data.filter((row) => {
      return row.make.toLowerCase().includes(searchInput.toLowerCase());
    });
    searchInput.length === 0
      ? setDisplayedData(data)
      : setDisplayedData(newData);
  }, [searchInput]);

  const EmptyIcon = (
    <img src={Empty} alt="Empty list" style={{ width: "100px" }} />
  );

  // const _getTrackersHealth = (imei) => {
  //     if(!imei || imei === ""){
  //         return "n/a"
  //     }
  //     props.getTrackersHealth(imei).then(res => {
  //         console.log(res?.payload?.data[0]?.status);
  //         return res?.payload?.data[0]?.status
  //     }).catch(e => {
  //         console.log(e)
  //         return "pending"
  //     })
  // }

  const vehicleListColumns = [
    // {
    //   title: "",
    //   dataIndex: "image",
    //   key: "image",
    //   // responsive: ['md'],
    //   render: (text) => <Avatar shape="square" src={text ? <Image src={text} /> : <Image src={empty} />} />,
    // },
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
      // responsive: ['md'],
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      responsive: ["md"],
    },
    {
      title: "Plate Number",
      dataIndex: "plate_number",
      key: "plate_number",
      responsive: ["md"],
    },
    {
      title: "Type",
      dataIndex: "size",
      key: "size",
      render: (text) => <p>{getKeyByValue(VEHICLE_TYPES, text)}</p>,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      // responsive: ['md'],
    },

    {
      title: "Status",
      dataIndex: "verification_status",
      key: "verification_status",
      render: (text) => (
        <div style={{ display: "flex", justifyContent: "start" }}>
          <VehicleStatusBadge status={text} />
        </div>
      ),
    },
    // {
    //   title: "Tracker Status",
    //   dataIndex: "tracker",
    //   key: "tracker",
    //   // responsive: ['md'],
    //   render: (text) => (
    //     <div style={{ display: "flex", justifyContent: "center" }}>
    //       {!text ? (
    //         <Spin indicator={true} />
    //       ) : (
    //         <StatusBadge status={text || "pending"} />
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      render: (text) => (
        <Link to={`/app/vehicle/${text}`}>
          <div style={{ width: "30px", height: "15px" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={View}
              alt="view"
            />
          </div>
        </Link>
      ),
      responsive: ["md"],
    },
  ];

  useEffect(() => {
    getVehicleData();
  }, [pageNumber, pageSize]);

  return (
    <div>
      <h2 className="header">
        Vehicles {totalVehicles > 0 && `(${totalVehicles})`}
      </h2>
      <div className="inner-container">
        <div className="driver-search-container">
          <Input
            placeholder="Search make"
            size="large"
            value={searchInput}
            className="past-trip-input"
            prefix={<SearchOutlined />}
            handleChange={handleSearchChange}
          />
          {!isEmployeeDriver && (
            <Button
              size={"large"}
              className="all-button"
              onClick={() => props.history.push("/app/add-vehicle")}
              type="primary"
            >
              Add New vehicle
            </Button>
          )}
        </div>
        <div className="table-container">
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  props.history.push(`/app/vehicle/${record._id}`);
                },
              };
            }}
            className="table"
            locale={{ emptyText: EmptyIcon }}
            loading={props.loadingVehicles}
            columns={vehicleListColumns}
            // dataSource={searchInput ? displayedData : data}
            dataSource={data}
            size="large"
            pagination={false}
            rowKey={(record) => record._id}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Pagination
              current={pageNumber}
              pageSize={pageSize}
              onChange={(page, pageNumber) =>
                handlePageChange(page, pageNumber)
              }
              total={totalVehicles}
              showSizeChanger={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, vehicles, loadingVehicles }) => ({
  user,
  vehicles,
  loadingVehicles,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleList);
