import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Modal,
  Form,
  Input,
  Spin,
  Avatar,
  Image,
  Menu,
  Dropdown,
  Pagination,
} from "antd";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Empty from "../assets/svgs/empty.svg";
import loadingIcon from "../components/common/loadingIcon";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import CustomInput from "../components/common/Input";
import { USER_STATUSES } from "../constants/Enums";
import empty from "../assets/empty.jpeg";
import "../styles/main.css";
import { toast } from "react-toastify";
import { authenticatedCompany } from "../helper/AuthHelper";
import { Link } from "react-router-dom";
import VehicleStatusBadge from "../components/common/VehicleStatusBadge";

const DriverList = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState(props.drivers);
  const [vehicles, setVehicles] = useState(props.vehicles);
  const [displayedData, setDisplayedData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [company] = useState(authenticatedCompany());
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDrivers, setTotalDrivers] = useState(0);

  const handleSearchChange = ({ currentTarget: input }) => {
    setSearchInput(input.value);
  };

  const submit = (driverId, vehicleId, driverVehicle, vehicle) => {
    const currentDriverId = vehicle.driver;
    const getCurrentDriverDetails = () =>
      data.find((a) => a._id === currentDriverId);
    const getPotentialDriverDetails = () =>
      data.find((a) => a._id === driverId);

    const message =
      getCurrentDriverDetails() && getCurrentDriverDetails()._id !== driverId
        ? `This vehicle is already assigned to ${
            getCurrentDriverDetails()?.full_name
          }, are you sure you want to reassign it to ${
            getPotentialDriverDetails()?.full_name
          }`
        : currentDriverId && currentDriverId === driverId
        ? `Are you sure you want to unassign this vehicle from ${
            getPotentialDriverDetails()?.full_name
          }`
        : `Are you sure you want to assign this vehicle to ${
            getPotentialDriverDetails()?.full_name
          }`;

    confirmAlert({
      title: "Confirm to submit",
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () => _assignVehicle(driverId, vehicleId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getDrivers = async () => {
    if (company) {
      await props
        .getCompanyDrivers(company._id, pageNumber - 1, pageSize)
        .then((res) => {
          setTotalDrivers(res.payload.data.count);
          const newData = [...res.payload.data.drivers];
          newData.forEach((driver) => {
            const newArray = driver.full_name.split(" ");
            if (newArray[0] === "undefined") {
              driver.full_name = newArray[1];
            }
            if (newArray[1] === "undefined") {
              driver.full_name = newArray[0];
            }
            if (newArray[0] !== "undefined" && newArray[1] !== "undefined") {
              driver.full_name = `${newArray[0]} ${newArray[1]}`;
            }
            if (driver.profile_picture) {
              driver.image = driver.profile_picture.original;
            }
          });

          setData(newData);
        })
        .catch((error) => {
          toast.error("Error getting company drivers");
          return error;
        });
    } else {
      return "No company found";
    }
  };

  const handlePageChange = (page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

  const _getAvailableDriver = () => {
    if (company) {
      props
        .getAvailableVehicles(company?._id)
        .then((res) => {
          setVehicles(res.payload.data.vehicles);
        })
        .catch((error) => {
          toast.error("Error getting available vehicles");
          return error;
        });
    }
  };

  const getPendingInvites = () => {
    if (company) {
      props
        .getPendingDrivers(company?._id)
        .then((res) => {
          // setVehicles(res.payload.data.vehicles);
        })
        .catch((error) => {
          toast.error("Error getting pending invites");
          return error;
        });
    }
  };

  const _assignVehicle = (driver_id, vehicle_id) => {
    props
      .assignVehicle(company?._id, driver_id, vehicle_id)
      .then((res) => {
        toast.success("Vehicle assignment successful");
        getDrivers();
        _getAvailableDriver();
      })
      .catch((error) => {
        toast.error("Error assigning vehicle");
        return error;
      });
  };
  useEffect(() => {
    setData(props.drivers);
  }, [props]);

  useEffect(() => {
    getDrivers();
    getPendingInvites();
  }, [company, pageNumber, pageSize]);

  useEffect(() => {
    _getAvailableDriver();
  }, [company]);

  useEffect(() => {
    const filteredData = data.filter(
      (driver) => driver.status === USER_STATUSES.VERIFIED
    );
    const newData = filteredData.filter((row) => {
      return row.full_name.toLowerCase().includes(searchInput.toLowerCase());
    });
    searchInput.length === 0
      ? setDisplayedData(data)
      : setDisplayedData(newData);
  }, [searchInput]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleAdd = async () => {
    setLoading(true);
    await props
      .addDriverToCompany(company._id, email)
      .then((res) => {
        setLoading(false);
        toast.success("Invitation sent successfully!");
        setEmail("");
      })
      .catch((error) => {
        setLoading(false);
        setEmail("");
        toast.error(error.error.response.data.message);
        return error;
      });
    setIsModalVisible(false);
  };

  const handleResetInvite = (email) => {
    setLoading(true);
    props
      .resendInvite(company._id, email)
      .then((res) => {
        toast.success("Invitation resent successfully!");
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error resending invite");
        setLoading(false);
        return error;
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEmail = ({ currentTarget }) => {
    setEmail(currentTarget.value);
  };

  const EmptyIcon = (
    <img src={Empty} alt="Empty list" style={{ width: "100px" }} />
  );

  const linkStyle = {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  };

  const driverListColumnsVer = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      // responsive: ['md'],
      render: (text, record) => (
        <Avatar
          shape="square"
          src={text ? <Image src={text} /> : <Image src={empty} />}
        />
      ),
    },
    {
      title: "Full name",
      dataIndex: "full_name",
      key: "full_name",
      // responsive: ['md'],
      render: (text, record) => (
        <Link
          to={`/app/driver/${record._id}`}
          style={linkStyle}
          className="driver-list-name"
        >
          {text}
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.79999 1.1999C2.37565 1.1999 1.96868 1.36847 1.66862 1.66853C1.36856 1.96859 1.19999 2.37556 1.19999 2.7999V9.1999C1.19999 9.62425 1.36856 10.0312 1.66862 10.3313C1.96868 10.6313 2.37565 10.7999 2.79999 10.7999H9.19999C9.62434 10.7999 10.0313 10.6313 10.3314 10.3313C10.6314 10.0312 10.8 9.62425 10.8 9.1999V7.1999C10.8 7.09382 10.8421 6.99207 10.9172 6.91706C10.9922 6.84204 11.0939 6.7999 11.2 6.7999C11.3061 6.7999 11.4078 6.84204 11.4828 6.91706C11.5579 6.99207 11.6 7.09382 11.6 7.1999V9.1999C11.6 9.83642 11.3471 10.4469 10.8971 10.897C10.447 11.347 9.83651 11.5999 9.19999 11.5999H2.79999C2.16347 11.5999 1.55303 11.347 1.10294 10.897C0.65285 10.4469 0.399994 9.83642 0.399994 9.1999V2.7999C0.399994 2.16338 0.65285 1.55293 1.10294 1.10285C1.55303 0.652759 2.16347 0.399902 2.79999 0.399902H4.79999C4.90608 0.399902 5.00782 0.442045 5.08284 0.51706C5.15785 0.592074 5.19999 0.693816 5.19999 0.799902C5.19999 0.905989 5.15785 1.00773 5.08284 1.08275C5.00782 1.15776 4.90608 1.1999 4.79999 1.1999H2.79999ZM6.79999 0.799902C6.79999 0.693816 6.84214 0.592074 6.91715 0.51706C6.99217 0.442045 7.09391 0.399902 7.19999 0.399902H11.2C11.3061 0.399902 11.4078 0.442045 11.4828 0.51706C11.5579 0.592074 11.6 0.693816 11.6 0.799902V4.7999C11.6 4.90599 11.5579 5.00773 11.4828 5.08275C11.4078 5.15776 11.3061 5.1999 11.2 5.1999C11.0939 5.1999 10.9922 5.15776 10.9172 5.08275C10.8421 5.00773 10.8 4.90599 10.8 4.7999V1.7655L7.48319 5.0831C7.40808 5.15821 7.30621 5.20041 7.19999 5.20041C7.09377 5.20041 6.9919 5.15821 6.91679 5.0831C6.84168 5.00799 6.79949 4.90612 6.79949 4.7999C6.79949 4.69368 6.84168 4.59181 6.91679 4.5167L10.2344 1.1999H7.19999C7.09391 1.1999 6.99217 1.15776 6.91715 1.08275C6.84214 1.00773 6.79999 0.905989 6.79999 0.799902Z"
              fill="#E27626"
            />
          </svg>
        </Link>
      ),
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      key: "phone_number",
      responsive: ["md"],
    },
    // {
    //   title: "Email Address",
    //   dataIndex: "email",
    //   key: "email",
    //   responsive: ["md"],
    // },
    // {
    //     title: 'Assign Truck',
    //     dataIndex: 'assign_truck',
    //     key: 'assign_truck',
    //     // responsive: ['md'],
    //     render: () =>     <Select defaultValue={vehicles[0]._id} style={{ width: 120 }}>
    //         {vehicles.map(vehicle => <Option key={vehicle._id} value={vehicle._id}>{vehicle.make} ({vehicle.plate_number})</Option>)}
    //         {/*<Option value="jack">Jack</Option>*/}
    //         {/*<Option value="lucy">Lucy</Option>*/}
    //     </Select>
    // },
    {
      title: "Assigned Truck",
      dataIndex: "assign_truck",
      key: "assigned_truck",
      // responsive: ['md'],
      render: (text, { vehicle }) =>
        vehicle?.id ? (
          <Link
            to={`/app/vehicle/${vehicle?.id}`}
            style={linkStyle}
            className="driver-list-name"
          >
            {vehicle?.make} {vehicle?.model} ({vehicle?.plate_number}) {""}
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.79999 1.1999C2.37565 1.1999 1.96868 1.36847 1.66862 1.66853C1.36856 1.96859 1.19999 2.37556 1.19999 2.7999V9.1999C1.19999 9.62425 1.36856 10.0312 1.66862 10.3313C1.96868 10.6313 2.37565 10.7999 2.79999 10.7999H9.19999C9.62434 10.7999 10.0313 10.6313 10.3314 10.3313C10.6314 10.0312 10.8 9.62425 10.8 9.1999V7.1999C10.8 7.09382 10.8421 6.99207 10.9172 6.91706C10.9922 6.84204 11.0939 6.7999 11.2 6.7999C11.3061 6.7999 11.4078 6.84204 11.4828 6.91706C11.5579 6.99207 11.6 7.09382 11.6 7.1999V9.1999C11.6 9.83642 11.3471 10.4469 10.8971 10.897C10.447 11.347 9.83651 11.5999 9.19999 11.5999H2.79999C2.16347 11.5999 1.55303 11.347 1.10294 10.897C0.65285 10.4469 0.399994 9.83642 0.399994 9.1999V2.7999C0.399994 2.16338 0.65285 1.55293 1.10294 1.10285C1.55303 0.652759 2.16347 0.399902 2.79999 0.399902H4.79999C4.90608 0.399902 5.00782 0.442045 5.08284 0.51706C5.15785 0.592074 5.19999 0.693816 5.19999 0.799902C5.19999 0.905989 5.15785 1.00773 5.08284 1.08275C5.00782 1.15776 4.90608 1.1999 4.79999 1.1999H2.79999ZM6.79999 0.799902C6.79999 0.693816 6.84214 0.592074 6.91715 0.51706C6.99217 0.442045 7.09391 0.399902 7.19999 0.399902H11.2C11.3061 0.399902 11.4078 0.442045 11.4828 0.51706C11.5579 0.592074 11.6 0.693816 11.6 0.799902V4.7999C11.6 4.90599 11.5579 5.00773 11.4828 5.08275C11.4078 5.15776 11.3061 5.1999 11.2 5.1999C11.0939 5.1999 10.9922 5.15776 10.9172 5.08275C10.8421 5.00773 10.8 4.90599 10.8 4.7999V1.7655L7.48319 5.0831C7.40808 5.15821 7.30621 5.20041 7.19999 5.20041C7.09377 5.20041 6.9919 5.15821 6.91679 5.0831C6.84168 5.00799 6.79949 4.90612 6.79949 4.7999C6.79949 4.69368 6.84168 4.59181 6.91679 4.5167L10.2344 1.1999H7.19999C7.09391 1.1999 6.99217 1.15776 6.91715 1.08275C6.84214 1.00773 6.79999 0.905989 6.79999 0.799902Z"
                fill="#E27626"
              />
            </svg>
          </Link>
        ) : (
          "---"
        ),
    },
    {
      title: "Reassign Truck",
      dataIndex: "assign_truck",
      key: "assign_truck",
      // responsive: ['md'],
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              {vehicles?.length === 0 ? (
                <p>No available vehicle</p>
              ) : (
                vehicles.map((vehicle, i) => (
                  <Menu.Item
                    key={record._id}
                    style={{ fontWeight: "300", color: "#252525" }}
                    onClick={() =>
                      submit(record._id, vehicle._id, record.vehicle, vehicle)
                    }
                  >
                    {vehicle._id === record.vehicle?.id
                      ? "Unassign"
                      : `${vehicle.make} ${vehicle.model} (${vehicle.plate_number})`}
                  </Menu.Item>
                ))
              )}
            </Menu>
          }
          placement="bottomCenter"
          disabled={record.is_driver_booked}
        >
          {record.vehicle ? (
            record.is_driver_booked ? (
              <p style={{ color: record?.is_driver_booked ? "gray" : "black" }}>
                Booked Driver
              </p>
            ) : (
              <p>
                {record.vehicle.make} {record.vehicle.model} (
                {record.vehicle.plate_number})<DownOutlined />
              </p>
            )
          ) : (
            <p>
              No truck <DownOutlined />
            </p>
          )}
        </Dropdown>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            textTransform: "uppercase",
          }}
        >
          <VehicleStatusBadge status={text} />
        </div>
      ),
    },
  ];

  const driverListColumns = [
    {
      title: "",
      dataIndex: "image",
      key: "driver-image",
      // responsive: ['md'],
      render: (text) => (
        <Avatar
          shape="square"
          src={text ? <Image src={text} /> : <Image src={empty} />}
        />
      ),
    },
    {
      title: "Full name",
      dataIndex: "full_name",
      key: "driver-full_name",
      render: (text, record) => (
        <p
          className="driver-list-name"
          onClick={() => props.history.push(`/app/driver/${record._id}`)}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Phone number",
      dataIndex: "phone_number",
      key: "driver-phone_number",
      responsive: ["md"],
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "driver-email",
      responsive: ["md"],
    },
    {
      title: "Invite",
      dataIndex: "resend_invite",
      key: "resend_invite",
      render: (text, record) => (
        <Button
          className="add-driver all-button"
          type="primary"
          onClick={() => handleResetInvite(record.email)}
        >
          Resend Invite
        </Button>
      ),
    },
    // {
    //     title: '',
    //     dataIndex: 'arrow',
    //     key: 'arrow',
    //     render: text => <RightOutlined />
    //     // responsive: ['md'],
    // },
  ];

  return (
    <div>
      <h2 className="header">
        Drivers {totalDrivers > 0 && `(${totalDrivers})`}
      </h2>
      <div className="inner-container">
        <div className="driver-search-container">
          <CustomInput
            placeholder="Search"
            size="large"
            value={searchInput}
            className="past-trip-input"
            prefix={<SearchOutlined />}
            handleChange={handleSearchChange}
          />
          <div className={"add-driver-container"}>
            <p onClick={() => props.history.push("/app/signup-driver")}>
              Sign Up Driver
            </p>
            <Button
              size={"large"}
              className="add-driver all-button"
              type="primary"
              onClick={showModal}
            >
              Add Driver
            </Button>
          </div>
        </div>
        <div className="table-container">
          <Table
            className="table"
            locale={{ emptyText: EmptyIcon }}
            columns={driverListColumnsVer}
            loading={props.loadingDrivers}
            dataSource={
              searchInput
                ? displayedData
                : data.filter(
                    (driver) => driver.status === USER_STATUSES.VERIFIED
                  )
            }
            size="large"
            pagination={false}
            rowKey={(record) => record._id}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Pagination
              current={pageNumber}
              pageSize={pageSize}
              onChange={(page, pageNumber) =>
                handlePageChange(page, pageNumber)
              }
              total={totalDrivers}
              showSizeChanger={true}
            />
          </div>
        </div>
      </div>
      <div className="inner-container pending-driver">
        <h2>Pending Invites</h2>
        <div className="table-container">
          <Table
            className="table"
            locale={{ emptyText: EmptyIcon }}
            columns={driverListColumns}
            loading={props.loadingPendingDrivers}
            dataSource={props.pendingDrivers?.map((el, i) => el?.employee)}
            size="large"
            pagination={false}
          />
        </div>
      </div>
      <Modal
        title="Add New Driver"
        className="add-driver-modal"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button
            className="modal-button"
            key="back"
            onClick={handleCancel}
            size={"large"}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="modal-button"
            type="primary"
            onClick={handleAdd}
            size={"large"}
          >
            {loading ? <Spin indicator={loadingIcon} /> : <span>Add</span>}
          </Button>,
        ]}
      >
        <Form layout="vertical" className="modal-form">
          <Form.Item
            label="Driver Email Address"
            name="email_address"
            rules={[{ message: "Please enter driver email", type: "email" }]}
            className="modal-label"
          >
            <Input
              className="modal-input"
              value={email}
              onChange={handleEmail}
              placeholder="Enter a registered driver's email..."
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({
  drivers,
  vehicles,
  loadingDrivers,
  loadingPendingDrivers,
  pendingDrivers,
}) => ({
  drivers,
  vehicles,
  loadingDrivers,
  loadingPendingDrivers,
  pendingDrivers,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(DriverList);
