import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxFunctions } from "../helper/ReduxHelper";
import { Button, Form, Input, Spin } from "antd";
import CustomSelect from "../components/common/Select";
import loadingIcon from "../components/common/loadingIcon";
import { toast } from "react-toastify";
import StatusBadge from "../components/common/StatusBadge";
import { MAX_WEIGHT, TRACKER_STATUSES, truckOptions } from "../constants/Enums";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

const Vehicle = (props) => {
  const [data, setData] = useState(props.vehicle);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [fileList, setFileList] = useState([]);
  // eslint-disable-next-line
  const [fileList1, setFileList1] = useState([]);
  const [disable, setDisabled] = useState(true);
  const [title, setTitle] = useState("Vehicle Details");
  const [trackerStatus, setTrackerStatus] = useState("pending");

  let history = useHistory();

  const axleTypeOptions = [{ value: "single" }, { value: "double" }];

  const handleSelectChange = (value) => {
    const newVehicle = { ...data };
    newVehicle.axle_type = value;
    setData(newVehicle);
  };

  const handleSave = () => {
    if (data?.max_weight > MAX_WEIGHT)
      return toast.error(
        `Max weight shouldn't be more than ${MAX_WEIGHT}Tonnes`
      );

    setLoading(true);
    props
      .updateVehicle(data, data._id)
      .then((res) => {
        setLoading(false);
        setDisabled(true);
        toast.success("Vehicle Updated");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error Updating Vehicle");
        return error;
      });
  };

  const deleteVehicle = () => {
    props
      .deleteVehicle(data._id)
      .then((res) => {
        history.push("/app/vehicle-list");
        toast.success("Vehicle Removed Successfully");
      })
      .catch((error) => {
        toast.error("Error Removing Vehicle");
        return error;
      });
  };

  const handleDelete = () => {
    confirmAlert({
      title: "Confirm",
      message:
        "Are you sure you want to remove this vehicle?. This action cannot be reversed.",
      buttons: [
        { label: "Yes", onClick: () => deleteVehicle() },
        { label: "No" },
      ],
    });
  };

  useEffect(() => {
    setLoading(true);
    props
      .getVehicle(props.match.params.id)
      .then(async (res) => {
        setData(res.payload.data.vehicle);
        setFileList([
          {
            uid: "current",
            name: "image",
            status: "done",
            url: data.image.original,
          },
        ]);
        setFileList1([
          {
            uid: "current",
            name: "image",
            status: "done",
            url: data.image.original,
          },
        ]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // toast.error('Error getting vehicle data!');
        return error;
      });
  }, [props.match.params.id]);

  const handleChange = ({ target: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const handleEditBtn = () => {
    setDisabled(false);
    setTitle("Edit Vehicle Details");
  };

  const verifyTrackingId = (val) => {
    if (/^\d{15,17}$/.test(val)) {
      props
        .getTrackersHealth([val])
        .then((res) => {
          setTrackerStatus(res?.payload?.data?.[0]?.status);
        })
        .catch((e) => {
          setTrackerStatus(TRACKER_STATUSES.INVALID);
        });
    } else {
      if (val?.length > 17) {
        setTrackerStatus(TRACKER_STATUSES.INVALID);
      } else {
        setTrackerStatus(TRACKER_STATUSES.PENDING);
      }
    }
  };
  const { tracking_id } = data;

  useEffect(() => {
    verifyTrackingId(tracking_id);
  }, [tracking_id]);

  return (
    <div className="edit-vehicle">
      <div className="vehicle-form ">
        <div className="basic-flex">
          <div className="left-flex">
            <div
              onClick={() => history.goBack()}
              style={{
                marginBottom: "3px",
                cursor: "pointer",
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 12H5M5 12L12 19M5 12L12 5"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h2>{title}</h2>
          </div>
          {disable ? (
            <Button
              style={{ width: "150px", borderRadius: "5px" }}
              type="primary"
              onClick={handleEditBtn}
              size={"large"}
            >
              Edit details
            </Button>
          ) : null}
        </div>
        <Form layout="vertical" name="login-form" onFinish={handleSave}>
          <Form.Item label="Make" className="label">
            <Input
              size={"large"}
              className="input"
              name="make"
              value={data.make}
              onChange={handleChange}
              disabled={disable}
            />
          </Form.Item>

          <Form.Item label="Model" className="label">
            <Input
              size={"large"}
              className="input"
              name="model"
              value={data.model}
              onChange={handleChange}
              disabled={disable}
            />
          </Form.Item>
          <Form.Item label="Color" className="label">
            <Input
              size={"large"}
              className="input"
              name="color"
              value={data.color}
              onChange={handleChange}
              disabled={disable}
            />
          </Form.Item>
          <Form.Item label="Plate Number" className="label">
            <Input
              size={"large"}
              className="input"
              name="plate_number"
              value={data.plate_number}
              onChange={handleChange}
              disabled={disable}
            />
          </Form.Item>
          {data?.registration_number && (
            <Form.Item label="Registration Number" className="label">
              <Input
                size={"large"}
                className="input"
                name="registration_number"
                value={data.registration_number}
                onChange={handleChange}
                disabled={disable}
              />
            </Form.Item>
          )}
          <Form.Item label="Engine Number" className="label">
            <Input
              size={"large"}
              className="input"
              name="engine_number"
              value={data.engine_number}
              onChange={handleChange}
              disabled={disable}
            />
          </Form.Item>
          <Form.Item label="Chassis Number" className="label">
            <Input
              size={"large"}
              className="input"
              name="chassis_number"
              value={data.chassis_number}
              onChange={handleChange}
              disabled={disable}
            />
          </Form.Item>
          <Form.Item
            label="Tracking ID"
            className="label"
            style={{ marginBottom: "10px" }}
          >
            <Input
              size={"large"}
              className="input"
              name="tracking_id"
              value={data.tracking_id}
              onChange={handleChange}
              disabled={true}
            />
          </Form.Item>
          <div style={{ marginBottom: "25px" }}>
            {!disable && <StatusBadge form status={trackerStatus} />}
          </div>
          <Form.Item label="Axle Type" className="label">
            <div className="selectContainer2">
              <CustomSelect
                options={axleTypeOptions}
                size="large"
                name="axle_type"
                handleChange={handleSelectChange}
                value={data.axle_type}
                bordered={false}
                disabled={disable}
              />
            </div>
            {/*<Input size={"large"} className='input' name='tracking_id' value={data.tracking_id} onChange={handleChange}/>*/}
          </Form.Item>
          <Form.Item label="Vehicle Type" className="label">
            <div className="selectContainer2">
              <CustomSelect
                options={truckOptions}
                size="large"
                name="data.size"
                handleChange={(value) => setData({ ...data, size: value })}
                value={data.size}
                bordered={false}
                disabled={disable}
              />
            </div>
            {/*<Input size={"large"} className='input' name='tracking_id' value={data.tracking_id} onChange={handleChange}/>*/}
          </Form.Item>
          <Form.Item
            label="Maximum Weight Supported (Ton Capacity)"
            className="label"
            style={{ marginBottom: "10px" }}
          >
            <Input
              size={"large"}
              className="input"
              type="number"
              max={MAX_WEIGHT}
              name="max_weight"
              value={data.max_weight}
              onChange={handleChange}
              disabled={disable}
            />
          </Form.Item>
          {data?.max_weight > MAX_WEIGHT && (
            <p style={{ color: "red" }}>
              Weight shouldn't exceed {MAX_WEIGHT}Tonnes
            </p>
          )}
          {/*<Form.Item label="Upload Picture of Road Worthiness" className='label'>*/}
          {/*    <ImageUpload data={data}*/}
          {/*                 loadingImage={loadingImage}*/}
          {/*                 uploadUrl={uploadUrl}*/}
          {/*                 fileList={fileList}*/}
          {/*                 imageUrl={imageUrl}*/}
          {/*                 setUploadUrl={setUploadUrl}*/}
          {/*                 sets3ImageUrl={sets3ImageUrl}*/}
          {/*                 setImageUrl={setImageUrl}*/}
          {/*                 setFileList={setFileList}*/}
          {/*                 setLoadingImage={setLoadingImage}*/}
          {/*                 s3ImageUrl={s3ImageUrl}*/}
          {/*                 listType = 'picture'*/}
          {/*                 disabled={disable}*/}
          {/*                 button={true}/>*/}
          {/*</Form.Item>*/}
          {/*<Form.Item label="Upload Picture of Insurance" className='label'>*/}
          {/*    <ImageUpload data={data}*/}
          {/*                 loadingImage={loadingImage}*/}
          {/*                 setLoadingImage={setLoadingImage}*/}
          {/*                 uploadUrl={uploadUrl1}*/}
          {/*                 fileList={fileList1}*/}
          {/*                 imageUrl={imageUrl1}*/}
          {/*                 setUploadUrl={setUploadUrl1}*/}
          {/*                 sets3ImageUrl={sets3ImageUrl1}*/}
          {/*                 setImageUrl={setImageUrl1}*/}
          {/*                 setFileList={setFileList1}*/}
          {/*                 s3ImageUrl={s3ImageUrl1}*/}
          {/*                 listType = 'picture'*/}
          {/*                 disabled={disable}*/}
          {/*                 button={true}/>*/}
          {/*</Form.Item>*/}

          {disable ? (
            <Button
              type="primary"
              block
              className="button all-button"
              size={"large"}
              style={{ marginTop: "40px", background: "red" }}
              onClick={handleDelete}
            >
              {props.loadingDeleteVehicle ? (
                <Spin indicator={loadingIcon} />
              ) : (
                <span>Remove Vehicle</span>
              )}
            </Button>
          ) : (
            <Button
              type="primary"
              block
              className="button all-button"
              size={"large"}
              style={{ marginTop: "40px" }}
              onClick={handleSave}
            >
              {loading ? <Spin indicator={loadingIcon} /> : <span>Save</span>}
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, vehicle, loadingDeleteVehicle }) => {
  return { user, vehicle, loadingDeleteVehicle };
};

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
