import React from "react";
import styled from "styled-components";
import { BiCurrentLocation } from "react-icons/bi";
import { USER_STATUSES, VERIFICATION_STATUSES } from "../../constants/Enums";

const VehicleStatusBadge = ({ status, form }) => {
  return (
    <Container status={status}>
      {form && <BiCurrentLocation />} <span>{status}</span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  span {
    // margin-left: 5px;
    background: #9e9e9e;
    background: ${({ status }) =>
      (status === VERIFICATION_STATUSES.VERIFIED ||
        status === USER_STATUSES.VERIFIED) &&
      "#4caf50"};
    background: ${({ status }) =>
      (status === VERIFICATION_STATUSES.IN_REVIEW ||
        status === USER_STATUSES.PENDING) &&
      "#9e9e9e"};
    color: white;
    font-size: 12px;
    padding: 4px 12px;
    border-radius: 5px;
  }
`;

export default VehicleStatusBadge;
