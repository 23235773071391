import React, { useEffect, useState } from "react";
import { Layout, Drawer, Modal } from "antd";
import TopNav from "../components/layout-components/TopNav";
import SideNav from "../components/layout-components/SideNav";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import PastTrips from "./PastTrips";
import PayoutScreen from "./PayoutScreen";
import useWindowSize from "../hooks/useWindowSize";
import "../styles/main.css";
import DriverList from "./DriverListScreen";
import VehicleList from "./VehicleListScreen";
import Vehicle from "./Vehicle";
import CompanyProfile from "./CompanyProfile";
import UserProfile from "./UserProfile";
import CalendarScreen from "./CalendarScreen";
import Insights from "./Insights";
import Dashboard from "./Dashbaord";
import UpcomingTrips from "./UpcomingTrips";
import LiveTrips from "./LiveTrips";
import LiveTrip from "./LiveTrip";
import Settings from "./settings/index";
import AddVehicle from "./addVehicle";
import CustomDrawer from "../components/common/Drawer";
import PaymentHistoryScreen from "./PaymentHistoryScreen";
import TripSummary from "./TripSummary";
import DriverDetails from "./DriverDetails";
import AvailableTrips from "./AvailableTrips";
import AvailableDrivers from "./AvailableDrivers";
import ScrollTop from "../components/util-components/ScrollTop";
import SignupDriver from "./SignupDriver";
import Tracking from "./tracking";
import PendingBids from "./bidding/PendingBids";
import Wallet from "./Wallet";
import DocumentVerificationModal from "../components/DocumentVerificationModal";
import { authenticatedCompany, authenticatedUser } from "../helper/AuthHelper";
import { DOCUMENT_TYPES, VERIFICATION_STATUSES } from "../constants/Enums";
import { reduxFunctions } from "../helper/ReduxHelper";
import { connect } from "react-redux";
import { navLinks } from "../configs/NavConfig";
import TripsWithPendingBids from "./bidding/TripsWithPendingBids";
import { config } from "../configs/app-config";

const { Content } = Layout;

const MainScreen = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [company, setCompany] = useState(authenticatedCompany());
  const [pageDetails, setPageDetails] = useState({});

  const size = useWindowSize();
  const { pathname } = useLocation();
  const history = useHistory();

  const user = authenticatedUser();
  const userType = user?.user_type;
  const isStandAloneDriver = userType === "driver" && !user?.is_employee;
  const cac_document = company?.documents?.find(
    (el) => el.document_type === DOCUMENT_TYPES.CAC
  );

  const getPageDetails = () => {
    const page = navLinks.find((p) => p.path === pathname);

    if (page) {
      setPageDetails(page);
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const toggle = () => {
    if (size.width <= 800) return setCollapsed(true);
    if (collapsed) {
      localStorage.setItem("sidebar", JSON.stringify("show"));
      setCollapsed(false);
    } else {
      localStorage.setItem("sidebar", JSON.stringify("hide"));
      setCollapsed(true);
    }
  };

  useEffect(() => {
    if (
      pageDetails &&
      pageDetails.path === pathname &&
      !pageDetails?.permission?.includes(userType) &&
      !(pageDetails?.permission?.includes("stand-alone") && isStandAloneDriver)
    ) {
      history.replace("/app/dashboard");
    }
  }, [pageDetails]);

  useEffect(() => {
    if (localStorage.getItem("sidebar")) {
      if (JSON.parse(localStorage.getItem("sidebar")) === "hide") {
        setCollapsed(true);
      }
    }
    getPageDetails();
  }, [pathname]);

  useEffect(() => {
    setCompany(authenticatedCompany());
  }, [props.loadingCOMPANY]);

  return (
    <div>
      <Layout>
        <TopNav
          collapsed={collapsed}
          toggle={toggle}
          showDrawer={showDrawer}
          onClose={onClose}
          history={props.history}
        />
        <Content>
          <Layout>
            {size.width >= 1000 && (
              <SideNav collapsed={collapsed} location={props.location} />
            )}
            <Content className="main-content">
              <Switch>
                <Route path="/app/signup-driver" component={SignupDriver} />
                <Route path="/app/trips/past-trips" component={PastTrips} />
                <Route path="/app/finance/wallet" component={Wallet} />
                <Route
                  path="/app/finance/payment-history"
                  component={PaymentHistoryScreen}
                />
                <Route path="/app/insights" component={Insights} />
                <Route path="/app/finance/payouts" component={PayoutScreen} />
                <Route path="/app/profile" component={UserProfile} />
                <Route path="/app/profile-company" component={CompanyProfile} />
                <Route path="/app/add-vehicle" component={AddVehicle} />
                <Route path="/app/calendar" component={CalendarScreen} />
                <Route
                  path="/app/trips/upcoming-trips"
                  component={UpcomingTrips}
                />
                <Route path="/app/available-trips" component={AvailableTrips} />
                {config.features.bidding && (
                  <>
                    <Route
                      path="/app/pending-bids/:id"
                      component={PendingBids}
                    />
                    <Route
                      path="/app/pending-bids"
                      component={TripsWithPendingBids}
                    />
                  </>
                )}
                <Route
                  path="/app/available-drivers/:id"
                  component={AvailableDrivers}
                />
                <Route path="/app/trips/live-trips" component={LiveTrips} />
                <Route path="/app/trips/offline-trip" component={Tracking} />
                <Route path="/app/live-trip/:id" component={LiveTrip} />
                <Route path="/app/trip-summary/:id" component={TripSummary} />
                <Route path="/app/vehicle/:id" component={Vehicle} />
                <Route path="/app/settings" component={Settings} />
                <Route path="/app/driver-list" component={DriverList} />
                <Route path="/app/driver/:id" component={DriverDetails} />
                <Route path="/app/vehicle-list" component={VehicleList} />
                <Route path="/app/dashboard" component={Dashboard} />
                <Redirect from="/app" to="/app/dashboard" />
              </Switch>
            </Content>
          </Layout>
        </Content>
        <Drawer
          title="Menu"
          placement="left"
          closable={true}
          onClose={onClose}
          visible={visible}
        >
          <CustomDrawer onClose={onClose} />
        </Drawer>
        <ScrollTop />
      </Layout>

      <Modal
        visible={
          cac_document?.status !== VERIFICATION_STATUSES.VERIFIED &&
          user?.user_type !== "driver"
        }
        width={500}
        centered
        maskClosable={false}
        closable={false}
        footer={""}
      >
        <DocumentVerificationModal />
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ loadingCOMPANY }) => ({ loadingCOMPANY });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
