import React, { useState } from "react";
import Modal from "react-awesome-modal";
import { Button, Form, Upload, Input, Spin, Checkbox, DatePicker } from "antd";
import { reduxFunctions } from "../helper/ReduxHelper";
import loadingIcon from "./common/loadingIcon";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../styles/auth.css";
import { toast } from "react-toastify";
import styled from "styled-components";

const RegisterDriverForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    family_name: "",
    given_name: "",
    phone_number: "",
    email: "",
    password: "",
    confirm_password: "",
    user_type: "driver",
  });
  const [licenseData, setLicenseData] = useState({
    license_number: "",
    license_image: "",
    expiry_date: "",
  });
  const [consent, setConsent] = useState(false);
  const [visible, setVisible] = useState(false);
  const [uploadUrl, setUploadUrl] = useState(null);
  // eslint-disable-next-line
  const [imageUrl, setImageUrl] = useState(null);
  const [s3ImageUrl, setS3ImageUrl] = useState(null);
  // eslint-disable-next-line
  const [fileList, setFileList] = useState([]);

  const { Dragger } = Upload;

  const _startLoading = () => {
    setLoading(true);
  };

  const _stopLoading = () => {
    setLoading(false);
  };

  const beforeUpload = async (file) => {
    const fileType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!fileType) {
      toast.error("You can only upload an acceptable file");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error("Image should not exceed 2MB");
      return;
    }
    await props
      .getUploadUrl(file.type)
      .then(async (res) => {
        let url = res.payload.data.upload.url;
        let path = res.payload.data.upload.path;
        setUploadUrl(url);
        setS3ImageUrl(path);
        return fileType && isLt2M;
      })
      .catch((e) => {
        toast.error("Image should not exceed 2MB");
        return;
      });
  };

  const handleFileChange = (info) => {
    setImageUrl(null);
    setFileList([]);
    // data.profile_picture = null;
    let file = info.file;
    if (file.status === "removed") {
      setImageUrl(null);
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
      // setLoadingImage(true);
    } else if (file.status === "done") {
      let url = s3ImageUrl;
      setFileList([
        { uid: "current", name: file.name, status: "done", url: url },
      ]);
      setImageUrl(url);
      // setLoadingImage(false);
    }
  };

  const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
    const xhr = new XMLHttpRequest();
    // S3 requires PUT method!
    xhr.open("PUT", uploadUrl);
    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // Calls the update prop
          setLicenseData({ ...licenseData, license_image: s3ImageUrl });
          setImageUrl(s3ImageUrl);
          onSuccess(null, file);
        } else {
          toast.error("Upload Failed");
        }
      }
    };
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        onProgress(
          { percent: Math.round((e.loaded / e.total) * 100).toFixed(2) },
          file
        );
      }
    };
    xhr.send(file);
  };

  const draggerProps = {
    name: "file",
    beforeUpload: beforeUpload,
    multiple: true,
    action: uploadUrl,
    onChange: handleFileChange,
    customRequest: (options) => handleUpload(options),
    // fileList: {fileList}
  };

  const { license_number, license_image, expiry_date } = licenseData;

  const password = data.password;

  const hasSixCharacters = password.trim()?.length >= 6;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);
  const hasSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
    password
  );

  const _signUpUser = async () => {
    if (!data.given_name) return toast.error("First name is required.");

    if (!data.family_name) return toast.error("Last name is required.");

    if (!data.email) return toast.error("Email is required.");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data.email))
      return toast.error("Please enter a valid email address.");

    if (!license_number || !license_image || !expiry_date)
      return toast.error("Please upload license details");
    if (!data.password) return toast.error("Password is required.");

    if (!hasSixCharacters)
      return toast.error("Password must contain at least 6 characters");
    if (!hasUpperCase)
      return toast.error(
        "Password must contain at least 1 uppercase character"
      );
    if (!hasLowerCase)
      return toast.error(
        "Password must contain at least 1 lowercase character"
      );
    if (!hasDigit) return toast.error("Password must contain at least 1 digit");
    if (!hasSpecialCharacter)
      return toast.error("Password must contain at least 1 special character");

    if (data.password !== data.confirm_password)
      return toast.error("Passwords do not match");

    if (!consent)
      return toast.error(
        "Please read and agree to our Terms of Use and Privacy Policy"
      );
    if (data.password === data.confirm_password) {
      _startLoading();
      await props
        .signUp({ ...data, drivers_license: { ...licenseData } })
        .then(async (res) => {
          toast.success("User created successfully");
          _stopLoading();
          props.handleRedirect();
        })
        .catch((error) => {
          toast.error(error.error?.response?.data?.message);
          _stopLoading();
          return error;
        });
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    const newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  return (
    <Container>
      <div className="inner">
        <div style={{ marginBottom: "20px" }}>
          <h2>Sign Up as a Driver</h2>
          <p className="p1">
            Already have an account? <Link to="/login"> Sign In</Link>
          </p>
        </div>

        <Form layout="vertical" name="login-form" onFinish={_signUpUser}>
          <Form.Item label="First name" className="label">
            <Input
              className="input"
              name="given_name"
              value={data.given_name}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item label="Last name" className="label">
            <Input
              className="input"
              name="family_name"
              value={data.family_name}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item label="Phone Number" className="label">
            <Input
              className="input"
              name="phone_number"
              value={data.phone_number}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item
            label="Email"
            rules={[
              {
                message: "Please input your email",
              },
              {
                type: "email",
                message: "Please enter a valid email!",
              },
            ]}
            className="label"
          >
            <Input
              className="input"
              name="email"
              value={data.email}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item label="License Number" className="label">
            <Input
              className="input"
              name="license_number"
              value={licenseData.license_number}
              onChange={(e) =>
                setLicenseData({
                  ...licenseData,
                  license_number: e.target.value,
                })
              }
            />
          </Form.Item>

          <Form.Item label="Upload License ID" className="label">
            <Dragger {...draggerProps}>
              <p style={{ fontWeight: "normal" }}>
                <span style={{ color: "#e27626", fontSize: "14px" }}>
                  Click or upload
                </span>{" "}
                or drag and drop
              </p>
              <p
                className="ant-upload-hint"
                style={{ fontSize: "12px", fontWeight: "normal" }}
              >
                PNG, JPG or PDF(max 2MB)
              </p>
            </Dragger>
          </Form.Item>

          <Form.Item label="License Expiry Date" className="label">
            <DatePicker
              picker="date"
              className="input"
              value={licenseData.expiry_date}
              style={{ width: "100%" }}
              onChange={(date) =>
                setLicenseData({ ...licenseData, expiry_date: date })
              }
            />
          </Form.Item>

          <Form.Item label="Password" className="label">
            <Input.Password
              className="input"
              name="password"
              value={data.password}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item label="Confirm Password" className="label">
            <Input.Password
              className="input"
              name="confirm_password"
              value={data.confirm_password}
              onChange={handleChange}
            />
          </Form.Item>

          <div className="check">
            <Checkbox checked={consent} onChange={() => setConsent(!consent)} />
            <p className="p2">
              By creating an account, I acknowledge that I have read,
              understood, and agree to the{" "}
              <a href="/terms" target="_blank" rel="noreferrer">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="/policy" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              .
            </p>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" block className="btn">
              {loading ? (
                <Spin indicator={loadingIcon} />
              ) : (
                <span>Create Account</span>
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        visible={visible}
        width="400"
        height="250"
        effect="fadeInUp"
        onClickAway={() => setVisible(false)}
      >
        <div className={"register-driver-modal"}>
          <h1>Action Disabled</h1>
          <p>Coming Soon...</p>
        </div>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  a,
  p {
    font-size: 14px;
  }

  .input {
    padding: 8px 12px;
    background: #fff;
    border-radius: 8px;
  }

  .check {
    display: flex;
    gap: 10px;
  }

  .btn {
    margin-top: 20px;
    padding: 12px;
    height: 3rem;
    border-radius: 8px;
  }
`;

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(RegisterDriverForm);
