import {
  getUpcomingTrips,
  logOutUser,
  createCost,
  setTripDetail,
  resetPassword,
  getPastTrips,
  requestTrip,
  requestNow,
  updateUser,
  getMe,
  setMyLocation,
  getOngoingTrips,
  getAcceptedTrips,
  getTripToken,
  getTrip,
  getCurrentTripwithId,
  cancelTrip,
  cancelCurrentTrip,
  loginUser,
  rateTrip,
  getRating,
  setCurrentTrip,
  signUp,
  createCompany,
  getUploadUrl,
  createVehicle,
  changePassword,
  getCompany,
  createTrip,
  getTrips,
  getVehicles,
  getVehicle,
  updateVehicle,
  deleteVehicle,
  updateCompany,
  addDriverToCompany,
  removeDriver,
  getCompanyDrivers,
  getPendingDrivers,
  requestPasswordResetEmail,
  acceptInvitation,
  getCompanies,
  getUserWithId,
  getCompanyDriverTrips,
  getAvailableTrips,
  updateTripStatus,
  getCompanyAvailableDrivers,
  getCompanyAvailableDriversByItemSize,
  verifyUser,
  resendVerificationCode,
  getAvailableVehicles,
  assignVehicle,
  resendInvite,
  signUpUserForCompany,
  getTrackersHealth,
  verifyTracker,
  fetchTerminals,
  createBid,
  makeBidDecision,
  getTripBids,
  getMetrics,
  createTracker,
} from "../redux/actions/Action";
import {
  otpValidation,
  deleteCard,
  initiatePayment,
  createCard,
  setActiveCard,
  getCardList,
  getPendingPayments,
  createBank,
  getAccounts,
  setActiveBank,
  deleteBank,
  getPaymentHistory,
  getPayouts,
  getBank,
  fundWallet,
  getRecentTransactions,
} from "../redux/actions/PaymentAction";

export const reduxFunctions = {
  otpValidation,
  loginUser,
  initiatePayment,
  createCard,
  setCurrentTrip,
  setActiveCard,
  getCardList,
  createTrip,
  requestTrip,
  getMe,
  createCost,
  updateUser,
  getTripToken,
  getPastTrips,
  getPendingPayments,
  setTripDetail,
  getUpcomingTrips,
  rateTrip,
  getTrip,
  logOutUser,
  resetPassword,
  getCurrentTripwithId,
  cancelTrip,
  cancelCurrentTrip,
  setMyLocation,
  getOngoingTrips,
  getRating,
  getAcceptedTrips,
  requestNow,
  deleteCard,
  signUp,
  createCompany,
  getUploadUrl,
  createVehicle,
  createBank,
  changePassword,
  getCompany,
  getAccounts,
  setActiveBank,
  deleteBank,
  getTrips,
  getVehicles,
  updateCompany,
  addDriverToCompany,
  removeDriver,
  getCompanyDrivers,
  getPendingDrivers,
  getPaymentHistory,
  getPayouts,
  getBank,
  requestPasswordResetEmail,
  getVehicle,
  updateVehicle,
  deleteVehicle,
  acceptInvitation,
  getCompanies,
  getUserWithId,
  getCompanyDriverTrips,
  getAvailableTrips,
  updateTripStatus,
  getCompanyAvailableDrivers,
  getCompanyAvailableDriversByItemSize,
  verifyUser,
  resendVerificationCode,
  getAvailableVehicles,
  assignVehicle,
  resendInvite,
  signUpUserForCompany,
  getTrackersHealth,
  verifyTracker,
  fetchTerminals,
  fundWallet,
  getRecentTransactions,
  createBid,
  makeBidDecision,
  getTripBids,
  getMetrics,
  createTracker,
};
